<template>
  <!-- App.vue -->
  <v-app>
    <v-navigation-drawer
      v-model="drawer"
      app
    >
      <!--  -->
    </v-navigation-drawer>
    <v-app-bar app>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title><v-img width="150" height="40" :src="logo"></v-img><!--JMR<span class="red--text">Climatização</span><v-icon right>mdi-content-cut</v-icon>--></v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn @click="social('www.youtube.com/channel/UCiClP_PGV0k8Cr_3SqBigyg')" icon>
        <v-icon>mdi-youtube</v-icon>
      </v-btn>
      <v-btn @click="message('Gostaria de Chamar o técnico da JMR')" icon>
        <v-icon>mdi-whatsapp</v-icon>
      </v-btn>
      <v-btn @click="social('www.instagram.com/thiago.meirelles.9237')" icon>
        <v-icon>mdi-instagram</v-icon>
      </v-btn>
      <v-btn @click="social('facebook.com/thiago.meirelles.58')" icon>
        <v-icon>mdi-facebook</v-icon>
      </v-btn>
    </v-app-bar>
    <!-- Sizes your content based upon application components -->
    <v-main>
        <!-- If using vue-router -->
      <router-view></router-view>      
    </v-main>
    <v-footer
      dark
      padless
    >
      <v-card
        flat
        tile
        class="white--text text-center"
      >
        
        <v-row>
          <v-card-text>
            Siga-nos
            <v-btn @click="social('www.youtube.com/channel/UCiClP_PGV0k8Cr_3SqBigyg')" icon>
              <v-icon>mdi-youtube</v-icon>
            </v-btn>
            <v-btn @click="message('Gostaria de Chamar o técnico da JMR')" icon>
              <v-icon>mdi-whatsapp</v-icon>
            </v-btn>
            <v-btn @click="social('www.instagram.com/thiago.meirelles.9237')" icon>
              <v-icon>mdi-instagram</v-icon>
            </v-btn>
            <v-btn @click="social('facebook.com/thiago.meirelles.58')" icon>
              <v-icon>mdi-facebook</v-icon>
            </v-btn>
            
          </v-card-text>

          <v-col cols="12" sm="4">
            <v-list dense>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Contato</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>  
                <v-list-item-content>
                  <v-list-item-subtitle><v-btn text :href="'tel:+5521964886631'">(21) 96488-6631</v-btn></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle><v-btn text :href="'tel:+51521993587041'">(21) 99358-7041</v-btn></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle><v-btn text :href="'mailto:thiagomeirelles@gmail.com'">thiagomeirelles@gmail.com</v-btn></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>                    
            </v-list>
          </v-col>
          <v-col cols="12" sm="4">
            <v-list dense>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Planos da JRM</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content @click="message('Gostaria de solicitar o Plano Basic Trimestral (90 dias)')">
                  <v-list-item-subtitle>Plano Basic Trimestral (90 dias)</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>  
                <v-list-item-content @click="message('Gostaria de solicitar o Plano Standard Semestral (180 dias)')">
                  <v-list-item-subtitle>Plano Standard Semestral (180 dias)</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content @click="message('Gostaria de solicitar o Plano Premium Anual (360 dias)')">
                  <v-list-item-subtitle>Plano Premium Anual (360 dias)</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              
            </v-list>
          </v-col>
          <v-col cols="12" sm="4">
            <v-list dense>
              <v-list-item> 
                
                <v-list-item-content>
                  <v-list-item-title>Serviços</v-list-item-title>
                </v-list-item-content>
              </v-list-item> 
              <v-list-item>
                <v-list-item-content @click="message('Gostaria de Chamar o técnico da JMR para instalação')">
                  <v-list-item-subtitle>Instalação</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content @click="message('Gostaria de Chamar o técnico da JMR para manutenção')">
                  <v-list-item-subtitle>Manutenção</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content @click="message('Gostaria de Chamar o técnico da JMR para limpeza')">
                  <v-list-item-subtitle>Limpeza</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>  
          </v-col>
          
          <v-card-text class="white--text">
            
            {{ new Date().getFullYear() }} — <strong>JMR solução em climatização e conforto térmico - CNPJ: 39.604.086/0001-05</strong>
          </v-card-text>
        </v-row>
      </v-card>
    </v-footer>
  </v-app>  
</template>
<script>
  export default {
    data: () =>
    ({ 
      logo: require("../src/assets/logo-sem-fundo.png"),
      drawer: false,
      icons: [
        'mdi-youtube',
        'mdi-whatsapp',
        'mdi-instagram',
        'mdi-facebook',
      ],
    }),
    methods: {
      message(obj){
        //window.location = `https://api.whatsapp.com/send?phone=5521999634039&text=${obj}`;
        window.open(`https://api.whatsapp.com/send?phone=5521981352741&text=${obj}`,'_blank');
      },
      social(obj){
        //window.location = `https://api.whatsapp.com/send?phone=5521999634039&text=${obj}`;
        window.open(`https://${obj}`,'_blank');
      }
    }
  }
</script>