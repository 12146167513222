
<template>
  <section>   
    <v-img
      class="white--text align-center"
      height="500px"
      :src="heroImg"
      gradient="to left, rgba(0,0,0,.0), rgba(0,0,0,1.9)"
    >
      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <v-card
            class="pa-md-10 mx-lg-auto"
            color="transparent"
            width="100%"
            elevation="0"
          >
            
            <v-list-item three-line>
              <!--
              <v-list-item-avatar
                size="80"
                color="grey"
              >
                <v-img
                  class="elevation-6"
                  alt=""
                  :src="logo"
                ></v-img>
              </v-list-item-avatar>
              -->
              <v-list-item-content>
                <v-list-item-title class="headline mb-1">
                  MANUTENÇÃO, INSTALAÇÃO OU LIMPEZA?
                </v-list-item-title>
                <!--
                <div class="caption">
                  Técnico Especialista da JMR em Refrigeração e Climatização, Instalação, Manutenção e Limpeza , Ar condicionado split * Bebedouro * Geladeira * Freezer * PMOC
                </div>
                --> 
                <v-btn
                  large
                  outlined
                  class="white black--text"
                  @click="message('Gostaria de Chamar o técnico da JMR')"
                >
                  
                  Chamar o técnico da JMR
                  <v-icon right>mdi-whatsapp</v-icon>
                </v-btn>
                <!--
                <div class="overline">
                  <v-icon>mdi-briefcase-variant</v-icon> Refrigeração e Climatização
                </div>
                <div class="overline">
                  <v-icon>mdi-nix</v-icon> Instalação, Manutenção e Limpeza
                </div>
                -->
              </v-list-item-content>
        
              
            </v-list-item>
            <!--
              , Ar condicionado split * Bebedouro * Geladeira * Freezer * PMOC
            <v-btn
              large
              outlined
              class="white black--text"
            >
              <v-icon>mdi-play</v-icon>
              Faça sua cotação
            </v-btn>
            -->
            
          </v-card>
          <!--
            <h1 class="display-1 font-weight-thin mb-4">
            Brutus Barbershop
            Segunda a Sábado de  9h as 19h
          </h1>
          -->
          

          <!--
          <v-img width="200" height="70" :src="logo"></v-img>                    
          -->
        </v-col>
        <v-col
          
          cols="12"
          sm="6"
        >
          <!--
          <v-card
            class="pa-md-4 mx-lg-auto"
            color="white"
            width="250px"
          >
            <v-card-text>
              <v-img width="200" height="70" :src="logo"></v-img>
              Adjust screen size to see spacing changes
            </v-card-text>
          </v-card>
          -->
          <!--
          <h1 class="display-1 font-weight-thin mb-4">
            Brutus Barbershop
          </h1>
          
          
          <h4 class="subheading">
            Segunda a Sábado de  9h as 19h
          </h4>
          
          <v-btn
            outlined
          >
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
          -->
        </v-col>
      </v-row>      
    </v-img>
    <!--
    <v-sheet
      class="mx-auto transparent"
      max-width="100%"
    >
      <v-slide-group
        v-model="model"
        center-active
        show-arrows
      >
        <v-slide-item
          v-for="n in barbeiro"
          :key="n"
          v-slot:default="{ active, toggle }"
        >

          <v-card
            width="300"
            :color="active ? 'primary' : 'grey lighten-1'"
            class="ma-1"
            height="400"
            @click="toggle"
          >
            <v-img
              height="400px"
              :src="n.img"
            >
              
              <v-card-title class="white--text mt-8">
                <v-avatar class="instagram" size="56">
                  <img
                    alt="user"
                    :src="n.img"
                  >
                </v-avatar>
                <p class="ml-3">
                  {{n.nome}}
                </p>
              </v-card-title>
            </v-img>
          </v-card>            
        </v-slide-item>
      </v-slide-group>
    </v-sheet>
    -->
    <v-container>
      
      <v-row align="center">
        <v-col cols="12" sm="12" class="subheader" align="center">
          Sobre a JMR<v-icon>mdi-chevron-down</v-icon>
        </v-col>
      </v-row>
      
      <v-row align="start">
        <v-col cols="12" sm="6" align="justify">
          <h2>Quem somos?</h2>
          <br>
          <h4>A JMR REFRIGERAÇÃO, CLIMATIZAÇÃO E CONFORTO TÉRMICO é uma empresa com compromisso e muita técnica para trazer conforto térmico para seu ambiente. Com qualidade nós serviços com muita responsabilidade sempre buscando conhecimento na área de climatização. Em outras áreas do seguimento na área de refrigeracao para sempre atender nossos clientes com muita responsabilidade e respeito com serviços de qualidade.</h4>
        </v-col>
        <v-col cols="12" sm="6" align="justify">
          <v-row>
          <v-col
            v-for="n in 6"
            :key="n"
            class="d-flex child-flex"
            cols="4"
          >
            <v-img
              :src="require(`../assets/galeria2/${n}.jpeg`)"
              :lazy-src="require(`../assets/galeria2/${n}.jpeg`)"
              aspect-ratio="1"
              class="grey lighten-2"
              @click="dialog = true, imgdialog = require(`../assets/galeria2/${n}.jpeg`)"
            >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col>
          </v-row>
          <!--
          <img
            :src="require('../assets/2.jpeg')"
            alt="teste"
            width="100%"
            height="300"
          >
          -->
        </v-col>
        <!--
        <v-col cols="6" align="justify">
          <img
            :src="require('../assets/3.jpeg')"
            alt="teste"
            width="100%"
            height="300"
          >
        </v-col>
        <v-col cols="6" align="justify">
          <h2>Sobre a JMR REFRIGERAÇÃO</h2>
          <h4>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sem nibh, dapibus id turpis in, feugiat pharetra purus. Vivamus convallis erat eget lacinia molestie. Aliquam libero urna, imperdiet sed ipsum at, cursus accumsan nunc. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Mauris non dui volutpat, dignissim lectus quis, posuere lectus. Aliquam condimentum consectetur ante nec efficitur. Nulla id enim pulvinar, tincidunt mauris et, fringilla leo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Donec id est vitae neque auctor elementum vitae ut justo. Nam tristique scelerisque fermentum. Sed in tincidunt leo. Nullam eu imperdiet enim. Nam semper leo eu diam rutrum lacinia. Nam fermentum leo nec lacus efficitur, tincidunt fermentum dui blandit. Nunc mattis neque ac turpis sagittis gravida.</h4>
        </v-col>
        -->
      </v-row>  
                  
      <v-row align="center">
        <v-col cols="12" class="subheader" align="center">
          Planos JMR<v-icon>mdi-chevron-down</v-icon>
        </v-col>
      </v-row>
     
      <v-row no-gutters>
        <v-col
          v-for="card in cards"
          :key="card.title"
          :sm="card.flex"
        >
          <v-card
            transparent
            class="ma-2"
          >
            <!--
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title class="headline">
                  {{card.plan}}
                </v-list-item-title>
                <v-list-item-subtitle>Mon, 12:30 PM, Mostly sunny</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            -->
            <v-card-text>
              <v-row align="center">
                <v-col cols="12" class="subheader" align="center">
                  {{card.plan}} <v-icon small right>mdi-sparkles</v-icon> 
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row align="center">
                <v-col cols="12" class="display-3 white--text" align="center">
                  {{card.number}}
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>
            <!--  
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-send</v-icon>
              </v-list-item-icon>
              <v-list-item-subtitle>23 km/h</v-list-item-subtitle>
            </v-list-item>

            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-cloud-download</v-icon>
              </v-list-item-icon>
              <v-list-item-subtitle>48%</v-list-item-subtitle>
            </v-list-item>
            -->
            <v-list class="transparent">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title v-text="card.text1"></v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon v-text="card.temp"></v-icon>
                </v-list-item-icon>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title v-text="card.text2"></v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon v-text="card.temp"></v-icon>
                </v-list-item-icon>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title v-text="card.text3"></v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon v-text="card.temp"></v-icon>
                </v-list-item-icon>
              </v-list-item>
              
            </v-list>

            <v-divider></v-divider>
            
            <v-card-actions>
              <v-btn
                  block
                  outlined
                  class="white black--text"
                  @click="message('Gostaria de contratar o '+card.plan+' da JMR')"
                >
                  Contratar agora
                  <v-icon right>mdi-whatsapp</v-icon>
                </v-btn>
            </v-card-actions>
            
          </v-card>
        </v-col>     
      </v-row>
      <v-row align="center">
        <v-col cols="12" class="subheader" align="center">
          Serviços da JMR<v-icon>mdi-chevron-down</v-icon>
        </v-col>
      </v-row>
      
      <v-row align="center">
        
        <v-col cols="12" align="justify">
          <v-row>
          <v-col
            v-for="n in 8"
            :key="n"
            class="d-flex child-flex"
            cols="3"
          >
            <v-img
              :src="require(`../assets/galeria3/${n}.jpeg`)"
              :lazy-src="require(`../assets/galeria3/${n}.jpeg`)"
              aspect-ratio="1"
              class="grey lighten-2"
              @click="dialog = true, imgdialog = require(`../assets/galeria3/${n}.jpeg`)"
            >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col>
          </v-row>
          <!--
          <img
            :src="require('../assets/2.jpeg')"
            alt="teste"
            width="100%"
            height="300"
          >
          -->
        </v-col>
        <!--
        <v-col cols="6" align="justify">
          <img
            :src="require('../assets/3.jpeg')"
            alt="teste"
            width="100%"
            height="300"
          >
        </v-col>
        <v-col cols="6" align="justify">
          <h2>Sobre a JMR REFRIGERAÇÃO</h2>
          <h4>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sem nibh, dapibus id turpis in, feugiat pharetra purus. Vivamus convallis erat eget lacinia molestie. Aliquam libero urna, imperdiet sed ipsum at, cursus accumsan nunc. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Mauris non dui volutpat, dignissim lectus quis, posuere lectus. Aliquam condimentum consectetur ante nec efficitur. Nulla id enim pulvinar, tincidunt mauris et, fringilla leo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Donec id est vitae neque auctor elementum vitae ut justo. Nam tristique scelerisque fermentum. Sed in tincidunt leo. Nullam eu imperdiet enim. Nam semper leo eu diam rutrum lacinia. Nam fermentum leo nec lacus efficitur, tincidunt fermentum dui blandit. Nunc mattis neque ac turpis sagittis gravida.</h4>
        </v-col>
        -->
      </v-row>
      <!--
      <v-row align="center">
        <v-col cols="12" class="subheader" align="center">
          Loja JMR
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-for="item in store"
          :key="item"
          class="d-flex child-flex"
          cols="3"
        >
          <v-card
            transparent
            
          >
            <v-img
              :src="item.src"
              :lazy-src="item.src"
              aspect-ratio="1"
              class="grey lighten-2"
            >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <v-card-actions>
              <v-btn
                block
                outlined
                class="white black--text"
              >
                Comprar 
                <v-icon right>mdi-cart</v-icon>
              </v-btn>
              
            </v-card-actions>
          </v-card>  
        </v-col>
      </v-row>
      -->
      <v-row align="center">
        <v-col cols="12" class="subheader" align="center">
          Canal da JMR no Youtube<v-icon>mdi-chevron-down</v-icon>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          class="d-flex child-flex"
          cols="12"
        >
          <v-card
            transparent
            
          >
            <iframe width="100%" height="315" src="https://www.youtube.com/embed/zgwwb4NOvOk" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </v-card>  
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="12" class="subheader" align="center">
          Outros serviços da JMR<v-icon>mdi-chevron-down</v-icon>
        </v-col>
      </v-row>
      
      <v-row align="center">
        
        <v-col cols="12" align="justify">
          <v-row>
          <v-col
            v-for="n in 8"
            :key="n"
            class="d-flex child-flex"
            cols="3"
          >
            <v-img
              :src="require(`../assets/galeria1/${n}.jpeg`)"
              :lazy-src="require(`../assets/galeria1/${n}.jpeg`)"
              aspect-ratio="1"
              class="grey lighten-2"
              @click="dialog = true, imgdialog = require(`../assets/galeria1/${n}.jpeg`)"
            >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col>
          </v-row>
          <!--
          <img
            :src="require('../assets/2.jpeg')"
            alt="teste"
            width="100%"
            height="300"
          >
          -->
        </v-col>
        <!--
        <v-col cols="6" align="justify">
          <img
            :src="require('../assets/3.jpeg')"
            alt="teste"
            width="100%"
            height="300"
          >
        </v-col>
        <v-col cols="6" align="justify">
          <h2>Sobre a JMR REFRIGERAÇÃO</h2>
          <h4>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sem nibh, dapibus id turpis in, feugiat pharetra purus. Vivamus convallis erat eget lacinia molestie. Aliquam libero urna, imperdiet sed ipsum at, cursus accumsan nunc. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Mauris non dui volutpat, dignissim lectus quis, posuere lectus. Aliquam condimentum consectetur ante nec efficitur. Nulla id enim pulvinar, tincidunt mauris et, fringilla leo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Donec id est vitae neque auctor elementum vitae ut justo. Nam tristique scelerisque fermentum. Sed in tincidunt leo. Nullam eu imperdiet enim. Nam semper leo eu diam rutrum lacinia. Nam fermentum leo nec lacus efficitur, tincidunt fermentum dui blandit. Nunc mattis neque ac turpis sagittis gravida.</h4>
        </v-col>
        -->
      </v-row>  
      <!--
      <v-row no-gutters>
        <v-col
          v-for="card in cards"
          :key="card.title"
          sm="12"
        >
          <v-banner two-line>
            <v-avatar
              size="50"
            >
              <img
                :src="card.src"
                :alt="card.title"
              >
            </v-avatar>

            {{card.title}}

            <template v-slot:actions>
              <v-btn
                text
                
              >
                Action
              </v-btn>
            </template>
          </v-banner>
        </v-col>     
      </v-row>
      -->     
      <v-dialog
        max-width="80%"
        v-model="dialog"
        transparent
      >
                  
        <v-card
          transparent
        >
          <v-card-actions class="justify-end">
            <v-btn
              text
              @click="dialog = false"
            >fechar</v-btn>
          </v-card-actions>
          <v-card-text>
            <v-img
              :src="imgdialog"
              :lazy-src="imgdialog"
              aspect-ratio="1"
              class="grey lighten-2"
            >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-card-text>
          
        </v-card>
        
      </v-dialog>
    </v-container>
  </section>
    
</template>

<script>
  export default {
    data: () => ({
      dialog: false,
      imgdialog: null,
      title: "Your Logo",
      logo: require("../assets/perfil.png"),
      heroImg: require("../assets/banner1.gif"),
      sectionImg: '', //require("../assets/background2.png"),
      barbeiro:[
        {
          nome:'Vinicius',
          img: require("../assets/logo.png"),
        },
        {
          nome:'Arthur',
          img: '', //require("../assets/arthur.png"),
        },
        {
          nome:'Samuel',
          img: '', //require("../assets/samuel.png"),
        },
        {
          nome:'Vinicius',
          img: '',  //require("../assets/vinicius.png"),
        },
        {
          nome:'Arthur',
          img: '', //require("../assets/arthur.png"),
        },
        {
          nome:'Samuel',
          img: '', //require("../assets/samuel.png"),
        },
      ],
      store: [
        { title: 'Instalação', plan: 'Plano Mensal', number: '30 dias', src: require("../assets/item1.jpeg"), flex: 3 },
        { title: 'Manutenção', plan: 'Plano Trimestral', number: '90 dias', src: require("../assets/item2.jpeg"), flex: 3 },
        { title: 'Limpeza', plan: 'Plano Semestral', number: '180 dias', src: require("../assets/item3.jpeg"), flex: 3 },
        { title: 'Prevenção', plan: 'Plano Anual', number: '360 dias', src: require("../assets/item4.jpeg"), flex: 3 }
      ],
      cards: [
        { title: 'Basic',
          text1: '1 visita técnica de 15 em 15 dias',
          text2: 'Atendimento segunda a sexta',
          text3: 'Revisão simples de equipamentos',
          plan:  'Plano Trimestral (90 dias)',
          number:'Basic',
          //src: require("../assets/galeria1/5.jpeg"),
          flex: 4,
          temp: 'mdi-check'
        },
        { title: 'Standard',
          text1: '2 visitas técnicas de 15 em 15 dias',
          text2: 'Atendimento segunda a sexta',
          text3: 'Revisão detelhada de equipamentos',
          plan: 'Plano Semestral (180 dias)',
          number: 'Standard',
          //src: require("../assets/galeria1/2.jpeg"),
          flex: 4,
          temp: 'mdi-check'
        },
        { title: 'Premium',
          text1: '1 visita técnicas a cada 7 dias',
          text2: 'Atendimento segunda a sábado',
          text3: 'Revisão completa de equipamentos',
          plan: 'Plano Anual (360 dias)',
          number: 'Premium',
          //src: require("../assets/galeria1/3.jpeg"),
          flex: 4,
          temp: 'mdi-check'
          }
      ],
      forecast: [
        { day: 'Plano Mensal', number: '30 dias', icon: 'mdi-white-balance-sunny', temp: 'mdi-check' },
        { day: 'Plano Trimestral', number: '90 dias', icon: 'mdi-white-balance-sunny', temp: 'mdi-check' },
        { day: 'plano Semestral', number: '180 dias', icon: 'mdi-cloud', temp: 'mdi-check' },
        { day: 'plano Anual', number: '360 dias', icon: 'mdi-cloud', temp: 'mdi-check' },
      ],
    }),
    methods: {
      message(obj){
        //window.location = `https://api.whatsapp.com/send?phone=5521999634039&text=${obj}`;
        window.open(`https://api.whatsapp.com/send?phone=5521981352741&text=${obj}`,'_blank');
      }
    }
  }
</script>
<style scoped>
  .instagram{
    border: 3px solid orangered;
  }
</style>